var formSelect = function () {
    var self = this;
    this.selectFields = document.getElementsByClassName('form-select');

    this.init = function () {
        this.copySelectFieldData();
        this.no_focus();
    };

    this.setPlaceholder = function (container, selectedSpan) {
        selectedSpan.innerText = container.getAttribute('data-placeholder');
    };

    this.createOption = function (val, txt) {
        var option = document.createElement('div');
        option.innerText = txt;
        option.setAttribute('data-value', val);
        option.classList.add('option');
        return option;
    };

    this.toggleList = function (e) {
        e.preventDefault();
    };

    this.selectOption = function (e) {
        e.preventDefault();
        var select = e.target.parentNode.parentNode.getElementsByTagName('select')[0];
        var selectedSpan = e.target.parentNode.parentNode.getElementsByClassName('selected-option')[0];
        selectedSpan.innerText = e.target.innerText;
        select.value = e.target.getAttribute('data-value');
    };

    this.copySelectFieldData = function () {

        for (var sfIndex = 0; sfIndex < this.selectFields.length; sfIndex++) {
            var optionList = document.createElement('div');

            var selectedSpan = document.createElement('span');
            selectedSpan.classList.add('selected-option');
            optionList.className = 'option-list';

            var sf = this.selectFields[sfIndex];
            var options = sf.getElementsByTagName('select')[0].querySelectorAll('option');
            sf.getElementsByTagName('select')[0].style.display = 'none';

            self.setPlaceholder(sf, selectedSpan);

            for (var oIndex in options) {
                if (oIndex >= 0) {
                    var option = options[oIndex];
                    var optionCopy = this.createOption(option.value, option.innerText);
                    optionCopy.onclick = function (e) {
                        self.selectOption(e);
                    };
                    if (option.value)
                        optionList.appendChild(optionCopy);
                }
            }
            sf.appendChild(selectedSpan);
            sf.appendChild(optionList);
            sf.onclick = function (e) {
                this.classList.toggle('active');
            };

        }
    };

    this.no_focus = function () {
        document.body.onclick = function (e) {
            for (var i = 0; i < self.selectFields.length; i++) {
                var formSelectField = self.selectFields[i];
                if (e.target !== formSelectField) {
                    formSelectField.classList.remove('active');
                }
            }
        };
    };

};

new formSelect().init();

var flipCard = function (options) {
    var self = this;

    this.init = function () {
        this.setupCards();
    };

    this.setupCards = function () {
        var cards = document.querySelectorAll(options.cardSelector);
        for (var i = 0; i < cards.length; i++) {
            var card = cards[i];
            card.onmouseenter = function (e) {
                // this.className = this.className.replace(' onmouseover', '');
                this.className = this.className + ' onmouseover';
            };
            card.onmouseleave = function (e) {
                this.className = this.className.replace(' onmouseover', '');
            };
        }
    };

};

new flipCard({
    cardSelector: '.event-group'
}).init();

new flipCard({
    cardSelector: '.news-group'
}).init();

var checkBoxFilter = function (options) {
    var self = this;
    this.selectedFilters = [];
    this.excludedFilters = [];

    this.init = function () {
        this.setup();
    };

    this.setup = function () {
        var checkboxes = document.querySelectorAll(options.controllerClass + ' input');
        var filterables = document.querySelectorAll(options.filterableClass);

        for (var i = 0; i < checkboxes.length; i++) {
            var checkbox = checkboxes[i];

            checkbox.onchange = function (e) {
                if (this.checked) {
                    var index = self.excludedFilters.indexOf(this.value);
                    self.selectedFilters.push(this.value);
                    self.excludedFilters.splice(index, 1);
                } else {
                    var index = self.selectedFilters.indexOf(this.value);
                    self.excludedFilters.push(this.value);
                    self.selectedFilters.splice(index, 1);
                }
                self.updateTargets();
            };
        }

        for (var i = 0; i < filterables.length; i++) {
            var filterable = filterables[i];
            filterable.setAttribute('data-width', filterable.style.width);
        }
    };

    this.updateTargets = function () {
        for (var i = 0; i < self.selectedFilters.length; i++) {
            var selected = self.selectedFilters[i];
            self.showFiltered(selected);
        }
        for (var i = 0; i < self.excludedFilters.length; i++) {
            var excluded = self.excludedFilters[i];
            self.hideExcluded(excluded);
        }
    };

    this.hideExcluded = function (value) {
        var targets = document.querySelectorAll(options.filterableClass + '.' + value);

        for (var i = 0; i < targets.length; i++) {
            var target = targets[i];
            target.className = target.className.replace(' filter-on', '');
            setTimeout(function () {
                target.style.width = '0';
            }, 300);
        }
    };

    this.showFiltered = function (value) {
        var targets = document.querySelectorAll(options.filterableClass + '.' + value);

        for (var i = 0; i < targets.length; i++) {
            var target = targets[i];

            target.style.width = target.getAttribute('data-width');
            setTimeout(function () {
                if (target.className.indexOf('filter-on') < 0)
                    target.className = target.className + ' filter-on';
                return;
            }, 300);
        }
    };

};

// new checkBoxFilter({
//     controllerClass: '.news-filter',
//     filterableClass: '.event-group'
// }).init();

/////////////////////////////////////
// jQuery reference after this line
/////////////////////////////////////
(function ($) {
    function setScrollTo(target, time) {
        $('html, body').stop().animate({
            scrollTop: target
        }, time);
    }
    ;

    $('.bring-me-top').on('click', function (e) {
        e.preventDefault();
        setScrollTo(0, 400);
    });

    //Quick-nav menu toggle (work together with primary-nav-link toggle)
    $('.quick-nav-label').on('click', function (e) {
        var ul = $(this).next();

        if (window.innerWidth < 992) {
            $('.primary-nav-item .sub-menu').removeClass('open');
            $('.primary-nav-item .sub-menu').prev().removeClass('openIcon');

            if (ul.hasClass("open")) {
                ul.removeClass("open");
                ul.prev().removeClass("open");
            } else {
                ul.addClass("open");
                ul.prev().addClass("open");
            }
        }
    });

    //Primary-nav menu toggle (work together with quick-nav toggle)
    $('.primary-nav-link').on('click', function (e) {
        var parent = $(this).parent();

        if (window.innerWidth < 992 && parent.hasClass('has-submenu')) {
            e.preventDefault();

            var isOpen = false;
            if ($(parent.find('.sub-menu').get(0)).hasClass('open')) {
                isOpen = true;
            }

            $(".quick-nav > ul").removeClass('open');
            $(".quick-nav > button").removeClass('open');
            $('.primary-nav-item .sub-menu').removeClass('open');
            $('.primary-nav-item .sub-menu').prev().removeClass('openIcon');

            if (isOpen == false) {
                $(parent.find('.sub-menu').get(0)).addClass('open');
                $(parent.find('.sub-menu').get(0)).prev().addClass('openIcon');
            }
        } else if ($(this).attr('href') == '#') {
            e.preventDefault();
        }
    });

    // CLICK EVENT
    $('#front-page-slider, #news-tiles, #event-tiles').on('click', '.carousel-item, .tile-group', function(evt) {
        var link = evt.currentTarget.getAttribute("data-href");
        window.location.href = link ? link : window.location.href;
    });

    $('.toggle-button').on('click', function (e) {
        e.preventDefault();
        var html = $('html');
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $('#navigation-bar').removeClass('open');

            $(".quick-nav > ul").removeClass('open');
            $(".quick-nav > button").removeClass('open');
            $('.primary-nav-item .sub-menu').removeClass('open');
            $('.primary-nav-item .sub-menu').prev().removeClass('openIcon');

            html.css('overflow', html.data('previous-overflow'));

            $(".top-nav").show();
            $(".quick-nav").show();
            $(".primary-nav").show();
            $(".menu-search-group").css("background-color", "transparent");
            $(".menu-search-group .input-group").css("border-bottom", "1px solid #fff");
            $(".close_search").css("display", "none");
            $("#menu-search-input").val('');
            $(".clear-input").css("display", "none");
            $(".quick-search-wrap").css("display", "none");
        } else {
            $(this).addClass('active');
            $('#navigation-bar').addClass('open');

            html.data('previous-overflow', html.css('overflow'));
            html.css('overflow', 'hidden');
        }
    });

    $(window).scroll(function (e) {
        if (window.innerWidth > 992) {
            if ($(document).scrollTop() > 100) {
                $('#navigation-bar').addClass('scrolled');
                $(".quick-nav").css("display","none");
                $(".top-nav").css("display","none");
                $('.collapsible-content').removeClass('search-active');
                $("#menu-search-input").val('');
            } else {
                $('#navigation-bar').removeClass('scrolled');
                $(".quick-nav").css("display","block");
                $(".top-nav").css("display","block");
            }
        }
    });

    $('#navigation-bar .search-btn').on('click', function (e) {
        $('.collapsible-content').addClass('search-active');

        setTimeout(function () {
            $('#menu-search-input').focus();
        }, 300);
    });

    $('#menu-search-input').focusout(function (e) {
        $('.collapsible-content').removeClass('search-active');
    });

    $('.menu-search-group input[type=text]').on('keyup', function (e) {
        var clear = $(this).parent().find('.clear-input')[0];
        if ($(this).val() !== '') {
            $(clear).show();
            if (window.innerWidth <= 992) {
                $(".quick-search-wrap").css("display", "block");
            }
        } else {
            $(clear).hide();
            if (window.innerWidth <= 992) {
                $(".quick-search-wrap").css("display", "none");
            }
        }
    });

    $('.menu-search-group .clear-input').on('click', function (e) {
        e.preventDefault();
        var input = $(this).parent().find('input[type=text]')[0];
        $(input).val('');
        $(input).focus();
        $(this).hide();
        $(".quick-search-wrap").css("display", "none");
    });

    $(".menu-search-group").focusin(function (e) {
        if (window.innerWidth <= 992) {
            $(".top-nav").hide();
            $(".quick-nav").hide();
            $(".primary-nav").hide();
            $(this).css("background-color", "#dd9f00");
            $(".menu-search-group .input-group").css("border", "none");
            $(".close_search").css("display", "block");
        }
    });

    $(".menu-search-group .close_search").on("click", function (e) {
        $(".top-nav").show();
        $(".quick-nav").show();
        $(".primary-nav").show();
        $(".menu-search-group").css("background-color", "transparent");
        $(".menu-search-group .input-group").css("border-bottom", "1px solid #fff");
        $(".close_search").css("display", "none");
        $("#menu-search-input").val('');
        $(".clear-input").css("display", "none");
        $(".quick-search-wrap").css("display", "none");
    });

    var filter = [];
    var sliders = {
        'event': [],
        'news': []
    };

    function updateFilter(filter, slider) {
        var slider_id = "";
        var temp_items = [];

        var slider_id = "#" + slider + "-tiles";

        if (sliders[slider].length === 0) {
            $.each($(slider_id + " .tile-group"), function (ind, elem) {
                sliders[slider].push(elem);
            });
        }
        $(slider_id + " .tile-group").remove();
        temp_items = sliders[slider].slice();

        if (filter.length > 0) {
            for (var i = 0; i < filter.length; i++) {
                var filter_i = 'category-' + filter[i];
                for (var j = 0; j < temp_items.length; j++) {
                    if ($(temp_items[j]).hasClass(filter_i)) {
                        $(slider_id + " .tiles-strip").append($(temp_items[j]));

                        var index = temp_items.indexOf(temp_items[j]);
                        if (index > -1) {
                            temp_items.splice(index, 1);
                            j--;
                        }
                    }
                }
            }
        } else {
            for (var i = 0; i < sliders[slider].length; i++) {
                $(slider_id + ' .tiles-strip').append($(sliders[slider][i]));
            }
        }

        this[slider + "Tiles"].initForOtherModules();
    }


    $('#filter-categories .btn-checkbox').on('click', function (e) {
        var input = $(this.children[0]);
        if (input.prop('checked')) {
            var index = filter.indexOf(input.val());
            if (index > -1) {
                filter.splice(index, 1);
            }
        } else {
            filter.push(input.val());
        }
        //updateFilter(filter, "event");
        updateFilter(filter, "news");
    });

    $('#gallery-modal').on('show.bs.modal', function (event) {
        var tile = $(event.relatedTarget);
        var id = tile.data('id');
        var src = tile.data('src');

        var carouselImages = $('#gallery-modal .carousel-item');
        var carouselImg = $('#gallery-modal .carousel-item[data-id="' + id + '"]');
        carouselImages.removeClass('active');
        carouselImg.addClass('active');
    });


    var headerBar = $('.header-bar');
    var clone = $('<div></div>');
    clone.addClass('header-bar-clone');
    $('header').prepend(clone);

    function setHeaderBarHeight() {
        if (window.innerWidth <= 992) {
            $('.header-bar-clone').height(headerBar.height());
        } else {
            $('.header-bar-clone').height(0);
        }

        if (window.innerWidth <= 1500) {
            $('.absolute-wrapper').css({
                paddingTop: 0+'px'
            });
        }else{
            $('.absolute-wrapper').css({
                paddingTop: headerBar.height()+'px'
            });
        }
    }

    setHeaderBarHeight();

    function setSearchToDefault() {
        $(".top-nav").show();
        $(".quick-nav").show();
        $(".primary-nav").show();
        $(".menu-search-group").css("background-color", "transparent");
        $(".menu-search-group .input-group").css("border-bottom", "1px solid #fff");
        $(".close_search").css("display", "none");
        $("#menu-search-input").val('');
        $(".clear-input").css("display", "none");
        $(".quick-search-wrap").css("display", "none");

        if( $("#navigation-bar").hasClass("scrolled") == true ){
            $(".quick-nav").css("display","none");
            $(".top-nav").css("display","none");
        }else{
            $(".quick-nav").css("display","block");
            $(".top-nav").css("display","block");
        }
    }


    $(window).resize(function () {
        setHeaderBarHeight();
        setMenuLayout();
        if (window.innerWidth > 992 ) {
            setSearchToDefault();
        }
        if (window.innerWidth > 1500 ) {
            $('.absolute-wrapper').css({
                paddingTop: headerBar.height()+'px'
            });
        }else{
            $('.absolute-wrapper').css({
                paddingTop: 0+'px'
            });
        }
    });


    function setMenuLayout() {
        var topLabels = $($('#nav-collapse .top-labels')[0]);
        var topNav = $($('#nav-collapse .top-nav')[0]);
        var xs_sm_bottom_bar = $($('.xs-sm-bottom-bar')[0]);

        if (window.innerWidth < 992) {
            topLabels.remove();
            topNav.append(topLabels);
            $("#navigation-bar").removeClass('scrolled');

            var windowHeight = window.innerHeight;
            var headerBarHeight = parseInt($(".header-bar").css("height"));
            var xsSmBottomBarHeight = parseInt($(".xs-sm-bottom-bar").css("height"));
            var scrollbarHeight = windowHeight - headerBarHeight - xsSmBottomBarHeight;

            $("#nav-collapse").css("max-height", scrollbarHeight + "px");


        } else {
            topLabels.remove();
            topLabels.insertBefore(xs_sm_bottom_bar);

            $("#nav-collapse").css("max-height", "auto");

            //Remove unnecessary classes when we change back to (winder-innerwidth>=992)
            $("html").css('overflow', 'visible');
            $("#navigation-bar").removeClass('open');
            $("#navigation-bar .toggle-button").removeClass('active');
            $(".quick-nav > ul").removeClass('open');
            $(".quick-nav > button").removeClass('open');
            $('.primary-nav-item .sub-menu').removeClass('open');
            $('.primary-nav-item .sub-menu').prev().removeClass('openIcon');
        }
    }

    setMenuLayout();

    //Select at "alapkezesek page"
    $(".training-level-search-section .select_header").on('click', function () {
        if ($(".training-level-search-section .select_items_main_container").hasClass('open')) {
            $(".training-level-search-section .select_items_main_container").removeClass('open');
        } else {
            $(".training-level-search-section .select_items_main_container").addClass('open');
        }
    });

    $(".training-level-search-section .select_items_sub_container").on('click', function () {
        var innerText = $(this).text();
        $(".training-level-search-section .select_header").text(innerText);
    });

    $("body").on('click', function (event) {
        var target = $(event.target);
        if (!target.hasClass("select_header")) {
            $(".training-level-search-section .select_items_main_container").removeClass('open');
        }
    });


    function copyHeight(nav, phantomnav) {
        if(window.innerWidth < 1500){
            phantomnav.height(nav.height());
        }else{
            phantomnav.height(0);
        }
    }

    (function (nav) {
        var phantomnav = $('<div></div>');
        phantomnav.attr('id', 'phantom-nav');
        $('body').prepend(phantomnav);

        copyHeight(nav, phantomnav);
        $(window).resize(function (e) {
            copyHeight(nav, phantomnav);
        });
    })($('#nav-collapse'));


    $('.custom-file').on('change', function(e){
        var displayPath = e.target.value.split('\\')[2];
        $($(this).find('.custom-file-control')[0]).attr('data-filename', displayPath);
    });
})(jQuery);
